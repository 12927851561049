<template>
    <div class="messages-page container">

        <!-- Conversations list -->
        <div class="col-md-6 conversations-list" v-if="!isMobile || (selectedConversationId === null && !fetchingMessages)">
            <h2 class="title">{{  $t('conversation.title' ) }}</h2>
            <ul class="list">

                <!-- No conversations message -->
                <li v-if="conversations.length === 0" class="no-conversations">
                    {{ $t('conversation.no-conversations') }}
                </li>

                <li
                    v-for="(conversation, index) in conversations"
                    :key="conversation.id"
                    class="conversation shadow-light"
                    :class="{ active: conversation.id === selectedConversationId }"
                    @click="selectConversation(conversation)"
                >
                    <!-- Picture-->
                    <img
                        :src="photoUrl(conversation.accommodation)"
                        class="accommodation-picture"
                    />
                    
                    <!-- Conversation title and message preview-->
                    <div class="conversation-text">
                        <div class="conversation-title">{{ conversation.title }}</div>
                        <div class="message-preview">{{ conversation.last_message }}</div>
                        <div class="accommodation-id">alojamiento número {{ conversation.accommodation.id }}</div>
                    </div>

                    <!-- Add unread behaviour -->
                    <div v-if="false" class="unread-dot"></div>

                </li>
            </ul>
        </div>

        <!-- Spinner -->
        <div class="d-flex justify-content-center align-items-center  w-100">
            <b-spinner v-if="fetchingMessages" type="grow" class="spinner" />
        </div>

        <!-- Messages list -->
        <div class="col-md-6 message-list" v-if="selectedConversationId !== null && !fetchingMessages">

            <!-- Conversation header-->
            <div v-if="isMobile" @click="deselectConversation" class="conversation-header" >
                
                <i class="back-icon" />

                <img
                    :src="photoUrl(selectedConversation.accommodation)"
                    class="accommodation-picture"
                />
                <div class="conversation-title">{{ selectedConversation.title }}</div>
                <div class="accommodation-id">({{ selectedConversation.accommodation.id }})</div>
            </div>
            
            <!-- Conversation messages -->
            <div class="messages" ref="messagesContainer">
                
                <!-- Message -->
                <div v-for="message in messages" :key="message.id" class="message-container">
                    <div :class="messageClassName(message)" class="message">
                        <div class="content"> {{ message.message }}</div>
                    </div>
                    <div class="timestamp" :class="messageClassName(message)">{{ formatDate(message.created_at) }}</div>
                </div>
            </div>

            <div class="users-data row">
                <!-- Sender user data -->
                <div class="receiver col-sm-12 col-md-6 pl-4">
                    <div class="email"> {{ selectedConversation.host_email}} </div>
                    <div class="phone"> {{ selectedConversation.host_phone}} </div>
                </div>
                <!-- Host user data -->
                <div class="sender col-sm-12 col-md-6 pr-4">
                    <div class="email"> {{ selectedConversation.guest_email}} </div>
                    <div class="phone"> {{ selectedConversation.guest_phone}} </div>
                </div>
            </div>


            

        </div>
        
    </div>
</template>


<script>
import ValidatedTextArea from "@/components/ValidatedFields/ValidatedTextArea.vue";
import { validations } from "@/components/ValidatedFields/conversationRules";
import {mapGetters, mapActions} from "vuex";
import {
    initializePusher,
    subscribeToChannel,
    unsubscribeFromChannel,
    disconnectPusher,
    
} from "@/Utils/PusherUtils";
import Button from "@/components/Ui/Button.vue";
import deviceMixin from "@/mixins/deviceMixin";

export default {
    mixins: [
        deviceMixin
        ],
    components: {
        Button,
        ValidatedTextArea
    },
    data() {
        return {
            url: process.env.VUE_APP_S3_URL,
            selectedConversationId: null,
            newMessage: "",
            accommodationId: null,
            formData: {
                message: ''
            },
            loading: false,
            fetchingMessages: false
        };
    },
    validations: validations,
    computed: {
        ...mapGetters("conversations", [
            "conversations",
            "messages",
            "selectedConversation",
        ]),
        // Select the sender of the first message as "me"
        userId() {
            return parseInt(this.messages[0].guest_id, 10);
        }
    },
    methods: {
        ...mapActions("conversations", [
            "fetchConversationsAdmin",
            "fetchMessagesAdmin",
            "addMessage"
        ]),
        
        async selectConversation(conversation) {

            this.fetchingMessages = true;

            if (this.selectedConversationId) {
                unsubscribeFromChannel(`conversation.${this.selectedConversationId}`);
            }
            
            this.selectedConversationId = conversation.id;
            
            try {
                
                await this.fetchMessagesAdmin(this.selectedConversationId);
                this.scrollToBottom();
                subscribeToChannel(
                    `conversation.${this.selectedConversationId}`,
                    "MessageConversationSent",
                    (data) => {
                        this.addMessage(data.message);
                        this.scrollToBottom();
                    }
                );
                setTimeout(() => {
                    this.fetchingMessages = false;
                }, 300);
            } catch (error) {
                console.error("Error fetching messages:", error);
                this.fetchingMessages = false;
            }
        },
        
        scrollToBottom() {
            this.$nextTick(() => {
                const container = this.$refs.messagesContainer;
                if (container) {
                    container.scrollTop = container.scrollHeight;
                }
            });
        },
        formatDate(date) {
            return new Date(date).toLocaleString();
        },
        messageClassName(message) {
            if(this.userId == message.sender_id) {
                return 'sent';
            } else {
                return 'received';
            }
        },
        photoUrl(accommodation) {
            // return 'http://localhost:8080/demo-image.jpg';
            const photo = accommodation.photos[0];
            return photo.url.indexOf('http') > -1 ? photo.url : this.url + photo.url;
        },
        deselectConversation() {
            this.selectedConversationId = null;
        }
    },
    async created() {
        try {
            initializePusher();
            await this.fetchConversationsAdmin();
            if (this.conversations.length > 0 && !this.isMobile) {
                this.selectConversation(this.conversations[0]);
            }
        } catch (error) {
            console.error("Error loading conversations:", error);
        }
    },
    beforeDestroy() {
        if (this.selectedConversationId) {
            unsubscribeFromChannel(`conversation.${this.selectedConversationId}`);
        }
        disconnectPusher();
    },
};
</script>


<style lang="scss" scoped>
@import "@/new-assets/styles";

.messages-page {
    min-height: 95vh;
    display: flex;
    flex-direction: row;
    padding: 20px;
}

.users-data {
    font-size: 1rem;
    color: $grey-75;
    .receiver {
        text-align: left;
    }
    .sender {
        text-align: right;
    }
}

.conversations-list {

    padding: 0;
    
    h2.title {
        padding: 0 0 0 10px;
    }

    .list {
        list-style: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .conversation {
        display: flex;
        align-items: center;
        padding: 15px;
        border: none;
        border-radius: 20px;
        cursor: pointer;
        position: relative;

        .accommodation-picture {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            border: 3px solid rgba($hf-orange, 0.3);
        }

        .conversation-text {
            display: flex;
            flex-direction: column;
            overflow: hidden;
            padding-left: 20px;
        }

        .conversation-title {
            font-size: 1rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .message-preview,
        .accommodation-id {
            font-size: 0.85rem;
            color: #777;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: normal;
        }

        .unread-dot {
            width: 10px;
            height: 10px;
            background-color: rgba($hf-primary, 0.9);
            border-radius: 50%;
            position: absolute;
            top: 10px;
            right: 10px;
            box-shadow: 0 0 3px 1px rgba($hf-primary, 0.7) !important;
        }
    }

    .unread {
        font-weight: bold;
    }

    
    li.active {
        border: 3px solid rgba($hf-orange, 0.3);
    }

    @media (min-width: $md) {
        padding: 20px 20px 0 20px;
    
        h2.title {
            padding: 0 0 0 20px;
        }
    }
    
    
}

.message-list {

    padding: 0;

    @media (min-width: $md) {
        padding: 20px 0;
    }

    .conversation-header {
        display: flex;
        align-items: center;
        padding: 0 0 15px 0;
        border: none;
        border-radius: 20px;
        cursor: pointer;
        position: relative;

        @media (min-width: $md) {
            padding: 20px 0;
            height: 65px;;
        }

        .back-icon {
            background-image: url("~@/new-assets/icons/back-icon.svg");
            background-repeat: no-repeat;
            width: 25px;
            height: 25px;
            padding: 10px 15px 20px 20px;
            margin-top: 15px;
        }

        .accommodation-picture {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            border: 3px solid rgba($hf-orange, 0.3);
        }

        .conversation-title {
            font-size: 1.2rem;
            font-weight: bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-left: 20px;
        }

        @media (min-width: $md) {
            font-size: 1.5rem;
        }
    }

    .messages {
        background-color: $grey-1;
        border-radius: $border-radius-new;
        overflow-y: auto;
        // height: 45vh;
        height: calc(100vh - 250px);
        padding: 30px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        
        .message-container {
            display: flex;
            flex-direction: column;
        }

        .message {
            border-radius: 20px;
            padding: 10px 20px 10px 20px;
            max-width: 80%;
            &.sent {
                align-self: flex-end;
                background-color: rgba(0, 61, 41, 0.21);
            }
            &.received {
                align-self: flex-start;
                background-color: rgba(252, 112, 16, 0.21);
            }
        }

        
        .timestamp, .email, .phone {
            font-size: 0.75rem;
            color: $grey-50;
            &.received {
                align-self: flex-start;
            }
            &.sent {
                align-self: flex-end;
            }
            
        }
    }
}

.spinner {
    color: $hf-primary !important;
}

.no-conversations {
    text-align: center;
    color: $grey-50;
    font-size: 1rem;
    padding: 20px;
}

</style>

